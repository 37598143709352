<template>
<div>

<section id="Exam" class="ma-0 mx-auto py-5 teal darken-2" style="color: #ddd">
<v-container>
  
<div id="AskExam" class="py-5 my-5 d-flex align-center justify-center animated fadeIn slow" v-if="askexam" style="flex-direction: column;">
  <p class="display-1 text-center my-5">Want To Test Your English Proficiency?</p>
  <p class="headline text-center my-5">Just Answer 15 Questions</p>
  <v-btn class="my-5 indigo darken-1 elevation-10 animated pulse infinite" large dark rounded @click="sFlag = true;askexam = !askexam" style="text-transform: capitalize">
    <span class="title">Yes, Test Me!</span>
  </v-btn>
  <p style="margin-top: 2rem;" class="mb-1 subtitle-1">Not Ready Enough?</p>
  <v-btn large rounded style="text-transform: Capitalize;color: #ddd" @click="$vuetify.goTo('#Courses', {duration: 20})" class="teal title darken-2 elevation-0">
    Join Our Spoken English Course
  </v-btn>
</div>

<article v-if="!askexam" class="animated fadeIn slow">
<div class="titleDiv px-5" style="border-left: 10px solid #ddd;">
  <p class="display-1">English Proficiency Test</p>
</div>
<div class="contentCard">

<v-stepper v-model="e1" v-if="e1 != 16" class="elevation-5" style="border-radius: 30px">
<v-stepper-items>

  <v-stepper-content step="1">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 1</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">_____ you finish, you'll be in trouble.</p>
        <v-radio-group v-model="a1">
          <v-radio label="Otherwise" value="otherwise"></v-radio>
          <v-radio label="Unless" value="unless"></v-radio>
          <v-radio label="If not" value="ifnot"></v-radio>
          <v-radio label="Can't" value="cant"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a1 == ''" @click="e1 = 2"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="2">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 2</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">The Company _____ accountant was arrested last week has gone bankrupt.</p>
        <v-radio-group v-model="a2">
          <v-radio label="Which" value="which"></v-radio>
          <v-radio label="Whose" value="whose"></v-radio>
          <v-radio label="Who" value="who"></v-radio>
          <v-radio label="When" value="when"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a2 == ''" @click="e1 = 3"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="3">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 3</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">I saw her just _____ day.</p>
        <v-radio-group v-model="a3">
          <v-radio label="Another" value="another"></v-radio>
          <v-radio label="On that day" value="onthatday"></v-radio>
          <v-radio label="That day" value="thatday"></v-radio>
          <v-radio label="The other" value="theother"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a3 == ''" @click="e1 = 4"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="4">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 4</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">This is the town _____ i was born in.</p>
        <v-radio-group v-model="a4">
          <v-radio label="Where" value="where"></v-radio>
          <v-radio label="When" value="when"></v-radio>
          <v-radio label="Which" value="which"></v-radio>
          <v-radio label="While" value="while"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a4 == ''" @click="e1 = 5"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="5">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 5</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">This is the town _____ i was born.</p>
        <v-radio-group v-model="a5">
          <v-radio label="Where" value="where"></v-radio>
          <v-radio label="When" value="when"></v-radio>
          <v-radio label="Which" value="which"></v-radio>
          <v-radio label="While" value="while"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a5 == ''" @click="e1 = 6"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="6">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 6</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">I'm not used to _____ that.</p>
        <v-radio-group v-model="a6">
          <v-radio label="Do" value="do"></v-radio>
          <v-radio label="Doing" value="doing"></v-radio>
          <v-radio label="Did" value="did"></v-radio>
          <v-radio label="Done" value="done"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a6 == ''" @click="e1 = 7"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="7">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 7</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">_____ your help, I would never have managed.</p>
        <v-radio-group v-model="a7">
          <v-radio label="But for" value="butfor"></v-radio>
          <v-radio label="Not" value="not"></v-radio>
          <v-radio label="Unless" value="unless"></v-radio>
          <v-radio label="Despite" value="despite"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a7 == ''" @click="e1 = 8"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="8">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 8</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">The two brothers do look _____ .</p>
        <v-radio-group v-model="a8">
          <v-radio label="Like" value="like"></v-radio>
          <v-radio label="Alike" value="alike"></v-radio>
          <v-radio label="Same like" value="samelike"></v-radio>
          <v-radio label="Equal" value="equal"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a8 == ''" @click="e1 = 9"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="9">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 9</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">No sooner _____ got there than they started annoying me.</p>
        <v-radio-group v-model="a9">
          <v-radio label="I had" value="ihad"></v-radio>
          <v-radio label="Had i" value="hadi"></v-radio>
          <v-radio label="Than" value="than"></v-radio>
          <v-radio label="While i" value="whilei"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a9 == ''" @click="e1 = 10"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="10">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 10</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">Only when _____ left, did we notice what she had done.</p>
        <v-radio-group v-model="a10">
          <v-radio label="I had" value="ihad"></v-radio>
          <v-radio label="Had i" value="hadi"></v-radio>
          <v-radio label="Than" value="than"></v-radio>
          <v-radio label="While i" value="whilei"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a10 == ''" @click="e1 = 11"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="11">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 11</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">I'd rather _____ go if you don't mind.</p>
        <v-radio-group v-model="a11">
          <v-radio label="Not" value="not"></v-radio>
          <v-radio label="Not to" value="notto"></v-radio>
          <v-radio label="To" value="to"></v-radio>
          <v-radio label="Not able to" value="notable to"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a11 == ''" @click="e1 = 12"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="12">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 12</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">It's high time you _____ that.</p>
        <v-radio-group v-model="a12">
          <v-radio label="Stop" value="stop"></v-radio>
          <v-radio label="To stop" value="tostop"></v-radio>
          <v-radio label="Stopped" value="stopped"></v-radio>
          <v-radio label="Stopping" value="stopping"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a12 == ''" @click="e1 = 13"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="13">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 13</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">"None of you are not good" means.</p>
        <v-radio-group v-model="a13">
          <v-radio label="Nothing of you is good" value="noyig"></v-radio>
          <v-radio label="All of you are not good" value="aoyang"></v-radio>
          <v-radio label="All of you are good" value="aoyag"></v-radio>
          <v-radio label="Not even one person of all of you is good" value="neop"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a13 == ''" @click="e1 = 14"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="14">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 14</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">I wish i _____ go there on friday.</p>
        <v-radio-group v-model="a14">
          <v-radio label="Could" value="could"></v-radio>
          <v-radio label="Can" value="can"></v-radio>
          <v-radio label="Will" value="will"></v-radio>
          <v-radio label="Would" value="would"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a14 == ''" @click="e1 = 15"> Continue </v-btn>
    </div>
  </v-stepper-content>

  <v-stepper-content step="15">
    <v-card class="mb-5 elevation-0">
      <v-card-title>
        <p class="display-1">Question 15</p>
      </v-card-title>
      <v-card-text>
        <p class="headline text--primary">If he _____, I won't stay.</p>
        <v-radio-group v-model="a15">
          <v-radio label="Come" value="come"></v-radio>
          <v-radio label="Came" value="came"></v-radio>
          <v-radio label="Comes" value="comes"></v-radio>
          <v-radio label="Will come" value="willcome"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div class="mb-5">
      <v-btn color="primary elevation-5" rounded :disabled="a15 == ''" @click="Validate()"> Finish </v-btn>
    </div>
  </v-stepper-content>

</v-stepper-items>
</v-stepper>

<div v-if="e1 == 16" class="py-5 animated fadeIn slow d-flex align-center justify-center text-center" style="flex-direction: column;margin-top: 5rem">
  <p class="display-1">Congratulations For Completing The Test!</p>
  <p class="headline mt-3">Your Score : {{counter}}</p>
  <p v-if="counter == 15" class="title">Excellent!</p>
  <p v-else-if="counter < 15 && counter > 10" class="title">Good But Need Improvement!</p>
  <p v-else class="title">You Need To Improve!</p>
  <v-btn class="my-5 indigo darken-1 elevation-10" large dark rounded @click="Finished()" style="text-transform: capitalize">
    <span class="title">Go Back!</span>
  </v-btn>
  <p style="margin-top: 3rem;" class="mb-1 subtitle-1">Need Professional Training?</p>
  <v-btn large rounded style="text-transform: Capitalize;color: #ddd" @click="$vuetify.goTo('#ContactUs', {duration: 20})" class="teal title darken-2 elevation-0">
    Request A Demo Class Now!
  </v-btn>
</div>

</div>
<div class="d-flex align-center justify-center my-5">
  <v-btn v-if="sFlag" class="my-5 red darken-1 elevation-10" large dark rounded @click="Finished()" style="text-transform: capitalize">
  <span class="title">I Surrender!</span>
  </v-btn>
</div>
</article>

</v-container>
</section>

</div>
</template>

<script>
export default {

    name: 'exam',
    
    data(){return{
        askexam: true,
        counter: 0,sFlag: true,
        e1: 0,a1: '',a2: '',a3: '',a4: '',a5: '',
        a6: '',a7: '',a8: '',a9: '',a10: '',a11: '',
        a12: '',a13: '',a14: '',a15: '',
    }},

    methods: {
    
      Validate(){
        this.e1 = 16;
        this.counter = 0;
        if(this.a1 == "unless"){this.counter++;}
        if(this.a2 == "whose"){this.counter++;}
        if(this.a3 == "theother"){this.counter++;}
        if(this.a4 == "which"){this.counter++;}
        if(this.a5 == "where"){this.counter++;}
        if(this.a6 == "doing"){this.counter++;}
        if(this.a7 == "butfor"){this.counter++;}
        if(this.a8 == "alike"){this.counter++;}
        if(this.a9 == "hadi"){this.counter++;}
        if(this.a10 == "ihad"){this.counter++;}
        if(this.a11 == "not"){this.counter++;}
        if(this.a12 == "stop"){this.counter++;}
        if(this.a13 == "aoyag"){this.counter++;}
        if(this.a14 == "could"){this.counter++;}
        if(this.a15 == "comes"){this.counter++;}
        this.sFlag = false;
      },
      Finished(){
        this.e1 = 0;
        this.counter = 0;
        this.a1 = '';this.a2 = '';this.a3 = '';this.a4 = '';this.a5 = '';this.a6 = '';
        this.a7 = '';this.a8 = '';this.a9 = '';this.a10 = '';this.a11 = '';this.a12 = '';
        this.a13 = '';this.a14 = '';this.a15 = '';
        this.askexam = true;
      },
    }
}
</script>

<style scoped>
#Exam{
  margin-bottom: 7rem;
  padding-top: 7rem !important;
  padding-bottom: 5rem !important;
}
.titleDiv{
  border-left: 10px solid #eee;
  margin-bottom: 3rem;
}
</style>